import React, { useEffect, useState } from 'react';
import { useUser, useAuth } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';

const SuccessPage = () => {
  const { isLoaded, isSignedIn, user } = useUser();
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);

  useEffect(() => {
    const fetchSubscriptionInfo = async () => {
      if (!isLoaded || !isSignedIn) return;

      try {
        const token = await getToken();
        const response = await fetch('https://usethevoice.com/api/get_user_subscription', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ clerkUserId: user.id }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch subscription info');
        }

        const data = await response.json();
        setSubscriptionInfo(data);
      } catch (error) {
        console.error('Error fetching subscription info:', error);
      }
    };

    fetchSubscriptionInfo();
  }, [isLoaded, isSignedIn, getToken, user]);

  if (!isLoaded || !subscriptionInfo) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="success-page">
      <div className="success-content">
        <h1>Thank you for your subscription!</h1>
        <div className="subscription-details">
          <h2>Your Subscription Details</h2>
          <div className="details-grid">
            <div className="detail-item">
              <label>Plan:</label>
              <span>{subscriptionInfo.subscriptionName}</span>
            </div>
            <div className="detail-item">
              <label>Monthly Limit:</label>
              <span>{subscriptionInfo.usageLimit.toLocaleString()} characters</span>
            </div>
            <div className="detail-item">
              <label>Current Usage:</label>
              <span>{subscriptionInfo.usageAmount.toLocaleString()} characters</span>
            </div>
          </div>
        </div>
        <button 
          onClick={() => navigate('/')}
          className="back-button"
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default SuccessPage;