import React, { useState, useEffect, useCallback } from 'react';
import DemoPlayer from './DemoPlayer';
import { useAuth, useUser } from '@clerk/clerk-react';
import './TTSBox.css';

const TTSBox = ({ onUsageUpdate }) => {
  const [inputText, setInputText] = useState('');
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [lastProcessedText, setLastProcessedText] = useState('');
  const [lastProcessedVoice, setLastProcessedVoice] = useState('alloy');
  const [audioHistory, setAudioHistory] = useState([]);
  const { getToken } = useAuth();
  const { user } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [expandedItems, setExpandedItems] = useState(new Set());
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  // Move fetchAudioHistory inside useCallback to memoize it
  const fetchAudioHistory = useCallback(async () => {
    try {
      const token = await getToken();
      const baseUrl = process.env.NODE_ENV === 'development' 
        ? "http://localhost:8000"
        : "https://usethevoice.com";
      
      const response = await fetch(`${baseUrl}/api/user-audios`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          clerkToken: token,
          clerkUserId: user.id
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch audio history');
      }

      const data = await response.json();
      setAudioHistory(data.files || []);
    } catch (err) {
      console.error('Error fetching audio history:', err);
      setError('Failed to load audio history');
    }
  }, [getToken, user?.id]); // Add dependencies here

  // Update useEffect dependencies
  useEffect(() => {
    if (user) {
      fetchAudioHistory();
    }
  }, [user, fetchAudioHistory]);

  // Update the second useEffect
  useEffect(() => {
    if (lastProcessedText) {
      fetchAudioHistory();
    }
  }, [lastProcessedText, fetchAudioHistory]);

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = audioHistory.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(audioHistory.length / itemsPerPage);

  const nextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  // Format date for display
  const formatDate = (isoDate) => {
    return new Date(isoDate).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  };

  const handleTextChange = (e) => {
    setInputText(e.target.value);
    setError(null);
  };

  const handleTTSRequest = async (text, voice = 'alloy') => {
    if (!text.trim()) {
      setError('Please enter some text');
      return null;
    }

    if (text === lastProcessedText && voice === lastProcessedVoice) {
      return { cached: true, text };
    }

    setIsProcessing(true);
    setError(null);
    
    try {
      const token = await getToken();
      const baseUrl = process.env.NODE_ENV === 'development' 
        ? "http://localhost:8000"
        : "https://usethevoice.com";
      console.log('Using backend URL:', baseUrl); // Debug log
      
      const response = await fetch(`${baseUrl}/api/tts/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token ? `Bearer ${token}` : '',
        },
        body: JSON.stringify({
          text: text,
          voice: voice,
          model: 'tts-1',
          speed: 1.0,
          deviceUUID: localStorage.getItem('deviceUUID') || null,
          clerkToken: token || null,
          clerkUserId: user.id
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to process text');
      }

      setLastProcessedText(text);
      setLastProcessedVoice(voice);
      
      if (onUsageUpdate) {
        await onUsageUpdate();
      }
      

      return response;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setIsProcessing(false);
    }
  };

  const toggleTextExpansion = (index) => {
    setExpandedItems(prev => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  const clearAllHistory = () => {
    setShowConfirmDialog(true);
  };

  const handleConfirmedClear = async () => {
    try {
      const token = await getToken();
      const baseUrl = process.env.NODE_ENV === 'development' 
        ? "http://localhost:8000"
        : "https://usethevoice.com";
      
      const response = await fetch(`${baseUrl}/api/clear-history`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          clerkToken: token,
          clerkUserId: user.id
        })
      });

      if (!response.ok) {
        throw new Error('Failed to clear history');
      }

      await fetchAudioHistory();
      setShowConfirmDialog(false);
      
    } catch (err) {
      console.error('Error clearing history:', err);
      setError('Failed to clear history');
    }
  };

  const deleteAudioItem = async (audioKey) => {
    // Optimistically remove the item from the UI
    const audioIndex = audioHistory.findIndex(
      audio => {
        const fullPath = new URL(audio.audio_url).pathname;
        const itemKey = `audios/${fullPath.split('/').slice(-3).join('/')}`;
        return itemKey === audioKey;
      }
    );
    
    if (audioIndex !== -1) {
      const newHistory = [...audioHistory];
      newHistory.splice(audioIndex, 1);
      setAudioHistory(newHistory);
    }

    try {
      const token = await getToken();
      const baseUrl = process.env.NODE_ENV === 'development' 
        ? "http://localhost:8000"
        : "https://usethevoice.com";
      
      const response = await fetch(`${baseUrl}/api/delete-audio`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          clerkToken: token,
          clerkUserId: user.id,
          audioKey: audioKey
        })
      });

      if (!response.ok) {
        throw new Error('Failed to delete audio');
      }
      
    } catch (err) {
      console.error('Error deleting audio:', err);
      setError('Failed to delete audio');
      // Revert the optimistic update on error
      await fetchAudioHistory();
    }
  };

  return (
    <div className="premium-tts-box">
      <h2>Text to Speech Studio</h2>
      <div className="tts-container">
        <div className="input-wrapper">
          <div className="animation-border"></div>
          <textarea
            className="tts-input"
            placeholder="Type or paste your text here..."
            value={inputText}
            onChange={handleTextChange}
            disabled={isProcessing}
          />
          {error && <div className="error-message">{error}</div>}
        </div>
        <div className="player-container">
          <DemoPlayer 
            customText={inputText} 
            onTTSRequest={handleTTSRequest}
            isProcessing={isProcessing}
            isInTTSBox={true}
            onAudioProcessed={fetchAudioHistory}
          />
        </div>
      </div>

      {/* Audio History Section */}
      <div className="history-section">
        <div className="history-header">
          <h3 className="history-title">History</h3>
          {audioHistory.length > 0 && (
            <button 
              className="clear-history-button"
              onClick={clearAllHistory}
            >
              Clear History
            </button>
          )}
        </div>
        <div className="audio-history">
          {currentItems.map((audio, index) => (
            <div 
              key={index} 
              className="audio-item"
            >
              <div className="audio-content" onClick={() => toggleTextExpansion(index)}>
                <p className={`audio-text ${expandedItems.has(index) ? 'expanded' : ''}`}>
                  {audio.text}
                </p>
              </div>
              <div className="audio-player">
                <div className="audio-date-voice">
                  <span className="audio-voice">{audio.voice}</span>
                  <span className="audio-date">{formatDate(audio.created_at)}</span>
                </div>
                <audio 
                  controls 
                  src={audio.audio_url}
                  onClick={(e) => e.stopPropagation()}
                />
                <button 
                  className="delete-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    const fullPath = new URL(audio.audio_url).pathname;
                    const audioKey = `audios/${fullPath.split('/').slice(-3).join('/')}`;
                   
                    deleteAudioItem(audioKey);
                  }}
                >
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M2 4h12M5.333 4V2.667a1.333 1.333 0 011.334-1.334h2.666a1.333 1.333 0 011.334 1.334V4m2 0v9.333a1.333 1.333 0 01-1.334 1.334H4.667a1.333 1.333 0 01-1.334-1.334V4h9.334z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
              </div>
            </div>
          ))}
        </div>
        
        {audioHistory.length > itemsPerPage && (
          <div className="pagination">
            <button 
              className="pagination-button"
              onClick={prevPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="pagination-info">
              Page {currentPage} of {totalPages}
            </span>
            <button 
              className="pagination-button"
              onClick={nextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        )}
      </div>

      {showConfirmDialog && (
        <div className="confirm-dialog-overlay">
          <div className="confirm-dialog">
            <h3>Clear History</h3>
            <p>This will delete all items in your history. Are you sure?</p>
            <div className="confirm-dialog-buttons">
              <button 
                className="confirm-dialog-button cancel"
                onClick={() => setShowConfirmDialog(false)}
              >
                Cancel
              </button>
              <button 
                className="confirm-dialog-button confirm"
                onClick={handleConfirmedClear}
              >
                Clear All
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TTSBox;